import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  format,
  subMonths,
  parse,
  formatISO,
  startOfDay,
  endOfDay,
} from 'date-fns';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';

import {
  createTable,
  getCoreRowModelSync,
  useTableInstance,
} from '@tanstack/react-table';
import {
  AutoCompleteInput,
  Button,
  CheckboxInput,
  Checkmark,
  Chip,
  DatePicker,
  Filters,
  Paginator,
} from '@nowsta/tempo-ds';

import { PunchcardFiltersSchema } from 'features/punchcards/schemas';

import { fetchPunchcards } from 'services/punchcards';
import { fetchCompaniesRequest } from 'features/companies/hooks/queries';

import {
  PunchcardStatus,
  PunchcardItem,
  SearchPunchcardsForm,
  StatusKey,
} from 'features/punchcards/types';
import EmptyState from 'features/common/components/EmptyState';
import { QueryKeys } from 'features/common/constants';

import CreateInvoiceDrawer from '../CreateInvoiceDrawer';
import FiltersDrawer from '../FiltersDrawer';

import ListSelectionInfoBox from './components/ListSelectionInfoBox';
import LoadingState from './components/LoadingState';
import { getTableData } from './helpers';

import { MAX_ITEMS_PER_PAGE, PUNCHCARD_HEADERS } from './constants';
import * as Presenter from './Presenter';
import ErrorState from './components/ErrorState';

const INITIAL_VALUES: SearchPunchcardsForm = {
  companyId: '',
  startDate: format(subMonths(new Date(), 1), 'MMM d, yyyy'),
  endDate: format(new Date(), 'MMM d, yyyy'),
  page: 1,
  limit: 10,
  event: '',
  eventDepartment: '',
  position: '',
  positionDepartment: '',
  status: {
    NEEDS_TO_BE_INVOICED: true,
    INVOICED: false,
    CORRECTED_NEEDS_TO_BE_INVOICED: true,
    STALE: false,
    PAID_TO_AGENCY: false,
    PAID_BY_CLIENT: false,
  },
};

const getPunchcardListStartDatetimeISO = (startDate: Date) =>
  formatISO(startOfDay(startDate));
const getPunchcardListEndDatetimeISO = (endDate: Date) =>
  formatISO(endOfDay(endDate));

export const PunchcardsListView = (
  props: React.ComponentPropsWithRef<'div'>,
) => {
  const [showFilters, setShowFilters] = useState(false);
  const [showCreateInvoice, setShowCreateInvoice] = useState(false);
  const [hasAllListSelected, setHasAllListSelected] = useState(false);

  const navigate = useNavigate();

  const [selectedPunchcards, setSelectedPunchcards] = useState<
    Record<string, Map<string, PunchcardItem>>
  >({});

  const methods = useForm<SearchPunchcardsForm>({
    defaultValues: INITIAL_VALUES,
    // @ts-ignore
    resolver: yupResolver(PunchcardFiltersSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const form = useWatch({ control: methods.control });

  const {
    formState: { errors },
    setValue,
    watch,
  } = methods;

  const updateQueryParams = (values: any) => {
    const params = new URLSearchParams(window.location.search);

    const filtersObj: any = {};
    for (const key of Object.keys(values)) {
      if (key === 'status') {
        filtersObj[key] = JSON.stringify(values[key]);
      } else {
        filtersObj[key] = values[key];
      }
    }
    params.set('punchcardsFilter', JSON.stringify(filtersObj));
    navigate(`?${params.toString()}`, { replace: true });
  };

  const handleClearSelection = useCallback(() => {
    setSelectedPunchcards({});
    setHasAllListSelected(false);
  }, [form.page]);

  useEffect(() => {
    const subscription = watch(values => {
      handleClearSelection();
      updateQueryParams(values);
    });
    return () => subscription.unsubscribe();
  }, [watch, handleClearSelection]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const punchcardsFilter: SearchPunchcardsForm = JSON.parse(
      queryParams.get('punchcardsFilter') || '{}',
    );

    const filtersObj: Partial<SearchPunchcardsForm> = {};

    for (const [key, value] of Object.entries(punchcardsFilter)) {
      if (key === 'status') {
        filtersObj[key] = JSON.parse(String(value));
      } else {
        // @ts-ignore
        filtersObj[key] = value;
      }
    }

    methods.reset({ ...methods.getValues(), ...filtersObj });
  }, [setValue, methods.reset]);

  const endDateWatch = watch('endDate');
  const statusCorrectedNeedsToBeInvoicedWatch = watch(
    'status.CORRECTED_NEEDS_TO_BE_INVOICED',
  );
  const statusInvoicedWatch = watch('status.INVOICED');
  const statusNeedsToBeInvoicedWatch = watch('status.NEEDS_TO_BE_INVOICED');
  const statusPaidByClientWatch = watch('status.PAID_BY_CLIENT');
  const statusPaidToAgencyWatch = watch('status.PAID_TO_AGENCY');
  const statusStaleWatch = watch('status.STALE');
  const startDateWatch = watch('startDate');
  const companyIdWatch = watch('companyId');
  const eventWatch = watch('event');
  const eventDepartmentWatch = watch('eventDepartment');
  const positionWatch = watch('position');
  const positionDepartmentWatch = watch('positionDepartment');

  useEffect(() => {
    methods.setValue('limit', 10, { shouldValidate: true, shouldDirty: true });
    methods.setValue('page', 1, { shouldValidate: true, shouldDirty: true });
  }, [
    methods.setValue,
    endDateWatch,
    statusCorrectedNeedsToBeInvoicedWatch,
    statusInvoicedWatch,
    statusNeedsToBeInvoicedWatch,
    statusPaidByClientWatch,
    statusPaidToAgencyWatch,
    statusStaleWatch,
    startDateWatch,
    companyIdWatch,
    eventWatch,
    eventDepartmentWatch,
    positionWatch,
    positionDepartmentWatch,
  ]);

  const statusSelected = useMemo(() => {
    const keys = Object.keys(form.status!);
    return keys.filter(
      key => form.status![key as keyof typeof PunchcardStatus],
    );
  }, [form.status]);

  const punchcardsQuery = useQuery(
    [QueryKeys.punchcardsList, { ...form, status: statusSelected }],
    () =>
      fetchPunchcards({
        params: {
          ...form,
          startDate: getPunchcardListStartDatetimeISO(
            parse(form.startDate!, 'MMM d, yyyy', new Date()),
          ),
          endDate: getPunchcardListEndDatetimeISO(
            parse(form.endDate!, 'MMM d, yyyy', new Date()),
          ),
          status: statusSelected,
        },
      }),
    {
      refetchOnWindowFocus: false,
      enabled: methods.formState.isValid && Boolean(form?.companyId),
      keepPreviousData: true,
    },
  );

  const companiesQuery = useQuery(
    [QueryKeys.companies, { offset: 1, limit: 0, search: '' }],
    () => fetchCompaniesRequest(1, 0, ''),
    {
      select: queryData => queryData?.items,
    },
  );

  const handlePaginationChange = useCallback(
    (param: 'limit' | 'page') => (value: number) => {
      methods.setValue(param, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [methods.setValue],
  );

  const handleFiltersVisibility =
    (open = false) =>
    () =>
      setShowFilters(open);

  const handleCreateInvoiceVisibility =
    (open = false) =>
    () =>
      setShowCreateInvoice(open);

  const table = createTable();
  const placeholderColumns = useMemo(() => PUNCHCARD_HEADERS, []);

  const handleCurrentPageSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedPunchcards(prevSelectedPunchcards => ({
        ...prevSelectedPunchcards,
        [form.page!]: new Map(
          punchcardsQuery.data?.items.map(punchcard => [
            punchcard.id.toString(),
            punchcard,
          ]),
        ),
      }));
    } else {
      setHasAllListSelected(false);
      setSelectedPunchcards(prevSelectedPunchcards => ({
        ...prevSelectedPunchcards,
        [form.page!]: new Map(),
      }));
    }
  };

  const handlePunchcardCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, id } = event.target;
      if (checked) {
        const punchcardItem = punchcardsQuery.data?.items.find(
          punchcard => punchcard.id.toString() === id,
        );
        setSelectedPunchcards(prevSelectedPunchcards => ({
          ...prevSelectedPunchcards,
          [form.page!]: prevSelectedPunchcards[form.page!]
            ? new Map(
                prevSelectedPunchcards[form.page!]?.set(id, punchcardItem!),
              )
            : new Map().set(id, punchcardItem),
        }));
      } else {
        setHasAllListSelected(false);
        setSelectedPunchcards(prevSelectedPunchcards => {
          const newSelectedPunchcards = new Map(
            prevSelectedPunchcards[form.page!],
          );
          newSelectedPunchcards.delete(id);
          return {
            ...prevSelectedPunchcards,
            [form.page!]: newSelectedPunchcards,
          };
        });
      }
    },
    [
      selectedPunchcards,
      setHasAllListSelected,
      setSelectedPunchcards,
      punchcardsQuery.data?.items,
      form.page,
    ],
  );

  const selectedCompany = useMemo(
    () =>
      companiesQuery.data?.find(
        company => company?.companyId === form?.companyId,
      ),
    [companiesQuery.data, form.companyId],
  );

  const data = useMemo(
    () =>
      punchcardsQuery.data?.items.map(punchcard => ({
        checkbox: (
          <CheckboxInput
            aria-label={`Select punchcard ${punchcard?.id.toString()}`}
            id={punchcard?.id.toString()}
            disabled={punchcardsQuery.isFetching}
            checked={
              selectedPunchcards[form.page!]?.has(punchcard?.id.toString()) ??
              false
            }
            onChange={handlePunchcardCheck}
          />
        ),
        ...getTableData({ punchcard }),
      })) ?? [],
    [
      punchcardsQuery.data?.items,
      punchcardsQuery.isFetching,
      selectedPunchcards,
      handlePunchcardCheck,
      form.page,
    ],
  );

  const punchcardsSelection = useMemo(() => {
    const pages = Object.keys(selectedPunchcards);
    const punchcards = pages.map(currentPage => {
      const map = selectedPunchcards[Number(currentPage)];
      return [...Array.from(map.values())];
    });

    return punchcards.flat();
  }, [selectedPunchcards]);

  const hasCurrentPageSelected = useMemo(() => {
    const currentPage = selectedPunchcards[form.page!];
    return (
      currentPage && currentPage.size === punchcardsQuery.data?.items.length
    );
  }, [selectedPunchcards, punchcardsQuery.data?.items]);

  const columns = table.createColumns(placeholderColumns);

  const instance = useTableInstance(table, {
    columns,
    data,
    getCoreRowModel: getCoreRowModelSync(),
  });

  const shouldDisableCreateInvoiceButton = !(
    punchcardsSelection.length > 0 && methods.formState.isValid
  );

  const isLoadingPunchcards =
    punchcardsQuery.isLoading && punchcardsQuery.fetchStatus !== 'idle';

  const maxLimit =
    Number(punchcardsQuery.data?.meta?.totalItems) > MAX_ITEMS_PER_PAGE
      ? MAX_ITEMS_PER_PAGE
      : Number(punchcardsQuery.data?.meta?.totalItems);

  const renderContent = () => {
    if (isLoadingPunchcards || punchcardsQuery.isFetching)
      return <LoadingState />;

    if (punchcardsQuery.isError)
      return <ErrorState error={punchcardsQuery.error} />;

    if (!punchcardsQuery.data?.items.length)
      return (
        <EmptyState message="No punchcards found for the criteria provided." />
      );

    return (
      <Presenter.TableContainer {...props}>
        <Presenter.Table {...instance.getTableProps()}>
          <Presenter.TableHeader>
            {instance.getHeaderGroups().map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    {!column.renderHeader() ? (
                      <CheckboxInput
                        aria-label="Select all punchcards"
                        disabled={
                          punchcardsQuery.isFetching &&
                          !punchcardsQuery.isFetched
                        }
                        checked={
                          selectedPunchcards[form.page!]?.size ===
                          punchcardsQuery.data?.items.length
                        }
                        onChange={handleCurrentPageSelection}
                      />
                    ) : (
                      column.renderHeader()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </Presenter.TableHeader>
          <Presenter.TableBody {...instance.getTableBodyProps()}>
            {instance.getRowModel().rows.map(row => (
              <tr {...row.getRowProps()}>
                {row.getVisibleCells().map(cell => (
                  <td role="presentation" {...cell.getCellProps()}>
                    <Presenter.RowText>{cell.renderCell()}</Presenter.RowText>
                  </td>
                ))}
              </tr>
            ))}
          </Presenter.TableBody>
        </Presenter.Table>
      </Presenter.TableContainer>
    );
  };

  const limitOptions = Array.from(new Set([10, 25, 50, 100, maxLimit]));

  return (
    <>
      {showCreateInvoice && (
        <CreateInvoiceDrawer
          company={selectedCompany}
          hasAllListSelected={hasAllListSelected}
          filters={methods.getValues()}
          visible={showCreateInvoice}
          selectedPunchcards={punchcardsSelection}
          onSuccess={handleClearSelection}
          endDate={getPunchcardListEndDatetimeISO(
            parse(form.endDate!, 'MMM d, yyyy', new Date()),
          )}
          startDate={getPunchcardListStartDatetimeISO(
            parse(form.startDate!, 'MMM d, yyyy', new Date()),
          )}
          onClose={handleCreateInvoiceVisibility(false)}
        />
      )}
      <FormProvider {...methods}>
        <FiltersDrawer
          onClose={handleFiltersVisibility(false)}
          visible={showFilters}
        />
        <Presenter.Header>Punchcards</Presenter.Header>
        <Presenter.FormWrapper>
          <Presenter.FiltersContainer>
            <Controller
              name="companyId"
              render={({ field }) => (
                <AutoCompleteInput
                  label="Company"
                  openOnFocus
                  inputValue={selectedCompany?.name || ''}
                  onSelected={selection => {
                    field.onChange(selection?.id);
                  }}
                  message={errors.companyId?.message}
                  palette={errors.companyId ? 'critical' : 'white'}
                  placeholder="Company"
                  {...field}
                  items={
                    companiesQuery.data?.map(company => ({
                      id: company.companyId,
                      displayValue: company.name,
                    })) ?? []
                  }
                />
              )}
            />

            <Controller
              name="startDate"
              render={({ field: { onChange, ...field } }) => (
                <DatePicker
                  spaceUI={{ pT: 3 }}
                  palette={errors.startDate ? 'critical' : 'white'}
                  message={errors.startDate?.message}
                  formatDate={date => format(new Date(date), 'MMM d, yyyy')}
                  label="Start date"
                  onChange={(...args) => {
                    onChange(...args);
                    methods.trigger('endDate', { shouldFocus: true });
                  }}
                  {...field}
                />
              )}
            />

            <Controller
              name="endDate"
              render={({ field: { onChange, ...field } }) => (
                <DatePicker
                  spaceUI={{ pT: 3 }}
                  formatDate={date => format(new Date(date), 'MMM d, yyyy')}
                  includeIcon
                  palette={errors.endDate ? 'critical' : 'white'}
                  message={errors.endDate?.message}
                  onChange={(...args) => {
                    onChange(...args);
                    methods.trigger('startDate', { shouldFocus: true });
                  }}
                  label="End date"
                  {...field}
                />
              )}
            />
            <Button
              palette="ui"
              disabled={!form.companyId}
              spaceUI={{ mT: 27 }}
              onClick={handleFiltersVisibility(true)}
              iconLeft={<Filters />}
            >
              Filters
            </Button>
          </Presenter.FiltersContainer>
          <Button
            spaceUI={{ mT: 27 }}
            disabled={shouldDisableCreateInvoiceButton}
            iconLeft={<Filters />}
            onClick={handleCreateInvoiceVisibility(true)}
          >
            New invoice
          </Button>
        </Presenter.FormWrapper>
      </FormProvider>
      {statusSelected.length > 0 && (
        <Presenter.StatusesWrapper>
          <Presenter.Title>Status:</Presenter.Title>
          <div>
            {Object.keys(PunchcardStatus)
              .filter(
                status =>
                  form?.status &&
                  form?.status[status as keyof typeof PunchcardStatus],
              )
              .map(status => (
                <Chip
                  key={status}
                  iconRight={<Checkmark />}
                  palette="success"
                  onClose={() => {
                    methods.setValue(`status.${status as StatusKey}`, false, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                  spaceUI={{ pR: 4 }}
                  uiSize="small"
                >
                  {String(PunchcardStatus[status as StatusKey])}
                </Chip>
              ))}
          </div>
        </Presenter.StatusesWrapper>
      )}

      <Presenter.InfoWrapper>
        <ListSelectionInfoBox
          hasAllListSelected={hasAllListSelected}
          totalSelected={selectedPunchcards[form.page!]?.size ?? 0}
          hasCurrentPageSelected={hasCurrentPageSelected}
          totalItems={punchcardsQuery.data?.meta?.totalItems ?? 0}
          isFetching={punchcardsQuery.isFetching}
          onClearSelection={handleClearSelection}
          onSelectionChange={setHasAllListSelected}
        />
      </Presenter.InfoWrapper>

      {renderContent()}

      {selectedCompany?.companyId && (
        <Paginator
          palette="white"
          uiSize="small"
          limitOptions={limitOptions}
          isWorking={isLoadingPunchcards}
          spaceUI={{ pT: 12, pB: 16 }}
          onLimitChange={handlePaginationChange('limit')}
          pageNumber={form.page}
          limit={form.limit}
          onPageNumberChange={handlePaginationChange('page')}
          totalPages={punchcardsQuery?.data?.meta?.totalPages ?? 0}
        />
      )}
    </>
  );
};
